import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './App.css';
import siteLogo from './images/logo_avatar.png';
import articleImage from './images/EBURP92.png';
import xLogo from './images/xlogo.svg';
import ordexLogo from './images/ordex.png';
import ethscriptionsLogo from './images/ethscriptions.png';
import etchLogo from './images/etch.png';
import flameIcon from './images/flame.png'; // Replace with your actual flame icon path

function App() {
  const [nfts, setNfts] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [owners, setOwners] = useState({});

  useEffect(() => {
    Papa.parse(process.env.PUBLIC_URL + '/eburp.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const typeOrder = ['Hero', 'Enemy', 'NPC'];
        const sortedNfts = result.data.sort((a, b) => {
          const indexA = typeOrder.indexOf(a.Type);
          const indexB = typeOrder.indexOf(b.Type);
          if (indexA === -1 || indexB === -1) return 0;
          return indexA - indexB;
        });
        setNfts(sortedNfts);
      },
    });
  }, []);

  const fetchOwner = async (ethscriptionId) => {
    try {
      const response = await fetch(`https://api.ethscriptions.com/api/ethscriptions/${ethscriptionId}`);
      if (response.ok) {
        const data = await response.json();
        setOwners(prev => ({
          ...prev,
          [ethscriptionId]: data.current_owner,
        }));
      } else {
        console.error(`Error fetching owner: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching owner:", error);
    }
  };

  const bgStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFFFFF',
    backgroundPosition: 'center center',
    backgroundSize: 'auto',
    backgroundAttachment: 'fixed',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '20px 0px 20px 0px',
  };

  const groupedNfts = nfts.reduce((group, nft) => {
    if (!group[nft.Type]) {
      group[nft.Type] = [];
    }
    group[nft.Type].push(nft);
    return group;
  }, {});

  const toggleExpanded = (index, nft) => {
    setExpandedIndices(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
    if (expandedIndices[index] !== true) {
      fetchOwner(nft.Ethscription_ID);
    }
  };

  const getOwnerIcon = (ownerLabel) => {
    if (ownerLabel === 'EBURP Vault') return '🔒';
    if (ownerLabel === 'BURNED') return '🔥';
    if (ownerLabel === 'Ethscriptions Marketplace') return '';
    if (ownerLabel === 'Ordex Marketplace') return '';
    return null;
  };
  
  const getOwnerLabel = (ownerAddress) => {
    const lowerCaseAddress = ownerAddress.toLowerCase();
    const mapping = {
      '0x719a411555ec93a896cf64dc07db72883fb57144': 'EBURP Vault',
      '0x000000000000000000000000000000000000dead': 'BURNED',
      '0xd729a94d6366a4feac4a6869c8b3573cee4701a9': 'Ethscriptions Marketplace',
      '0xc33f8610941be56fb0d84e25894c0d928cc97dde': 'Ordex Marketplace'
    };
    return mapping[lowerCaseAddress] || ownerAddress.replace(/(.{4}).*(.{4})$/, "$1...$2");
  };

  return (
    <div className="App" style={bgStyle}>
      <header className="App-header">
        <div className="Content-container">
          <div className="Top-row">
            <img src={siteLogo} className="Site-logo" alt="Site Logo" />
            <div className="Links">
              <a
                className="Twitter-link"
                href="https://www.x.com/eburp_art"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xLogo} alt="Follow us on X" width="40" height="40" />
              </a>
            </div>
          </div>
          <div className="Medium-article-promo">
            <img src={articleImage} alt="The eBURP Collection" className="Article-thumbnail" />
            <div className="Article-details">
              <h2>The B.C. (Before CryptoPunks) Era of LarvaLabs</h2>
              <p>An in-depth look into the EBURP Collection, the pixel assets that inspired the CryptoPunks collection.</p>
              <a href="https://medium.com/@Posvar/the-eburp-collection-bridging-larvalabs-pixel-past-to-blockchain-present-d9e20f00bd01" target="_blank" rel="noopener noreferrer" className="Read-more-button">Read More</a>
            </div>
          </div>
          {Object.keys(groupedNfts).map((type, typeIndex) => {
            const typeMapping = {
              Hero: 'HEROES',
              Enemy: 'ENEMIES',
              NPC: 'NPCS'
            };
            return (
              <div className="Nft-section" key={typeIndex}>
                <h2 className="Section-title">{typeMapping[type]}</h2>
                <div className="Nft-cards">
                  {groupedNfts[type].map((nft, index) => {
                    const isExpanded = expandedIndices[nft.item_index] === true;
                    const ownerLabel = getOwnerLabel(owners[nft.Ethscription_ID] || '');
                    const ownerIcon = getOwnerIcon(ownerLabel);

                    return (
                      <div
                          className={`Nft-card ${isExpanded ? 'expanded' : ''} ${parseInt(nft.item_index, 10) >= 92 ? 'special-bg' : ''}`}
                          key={index}
                          onClick={() => toggleExpanded(nft.item_index, nft)}
                      >
                        <img
                          src={nft['Data URI']}
                          alt={nft.name}
                          style={{
                            imageRendering: 'pixelated',
                            width: isExpanded ? '256px' : '48px',
                            height: isExpanded ? '256px' : '48px'
                          }}
                        />
                        <div className="Nft-details">
                          <h3>{nft.name}</h3>
                          {isExpanded && (
                            <>
                              <p><i>{nft.description}</i></p>
                              <p><strong>Sprite ID:</strong> {nft.item_index}</p>
                              <p><strong>Type:</strong> {nft.Type}</p>
                              {nft.Type === 'Hero' && nft['Hero Class'] ? <p><strong>Hero Class:</strong> {nft['Hero Class']}</p> : null}
                              <p><strong>Owner:</strong> {ownerLabel} {ownerIcon && ownerIcon}</p>
                              <div className="Marketplace-links">
                                {(ownerLabel !== 'Ethscriptions Marketplace' && ownerLabel !== 'Ordex Marketplace') && (
                                  <>
                                    <a href={`https://ethscriptions.com/ethscriptions/${nft.Ethscription_ID}`} target="_blank" rel="noreferrer">
                                      <img src={ethscriptionsLogo} alt="Ethscriptions" />
                                    </a>
                                    <a href={`https://ordex.ai/ethscription/${nft.Ethscription_ID}`} target="_blank" rel="noreferrer">
                                      <img src={ordexLogo} alt="Ordex" />
                                    </a>
                                    <a href={`https://www.etch.market/ethscriptions/${nft.Ethscription_ID}`} target="_blank" rel="noreferrer">
                                      <img src={etchLogo} alt="Etch" />
                                    </a>
                                  </>
                                )}
                                {ownerLabel === 'Ethscriptions Marketplace' && (
                                  <a href={`https://ethscriptions.com/ethscriptions/${nft.Ethscription_ID}`} target="_blank" rel="noreferrer">
                                    <img src={ethscriptionsLogo} alt="Ethscriptions" />
                                  </a>
                                )}
                                {ownerLabel === 'Ordex Marketplace' && (
                                  <a href={`https://ordex.ai/ethscription/${nft.Ethscription_ID}`} target="_blank" rel="noreferrer">
                                    <img src={ordexLogo} alt="Ordex" />
                                  </a>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </header>
    </div>
  );
}

export default App;